import * as React from "react";
import "./ContentButton.scss";
import { AppState } from "./../../redux/Store";
import { connect } from "react-redux";
import { ContentEntry } from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import Helper from "../utils/Helper";

type StateProps = {};
type DispatchProps = {
  selectContent?: (content: ContentEntry | null) => DataActionSelectContent;
};
type Props = {
  content: ContentEntry;
};

type State = { counter: number };

class ContentButton extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = { counter: 0 };

  _intervalID: NodeJS.Timeout | null = null;
  componentDidMount() {
    this._intervalID = setInterval(() => {
      this.setState({ counter: this.state.counter + 1 });
    }, 1000 * 60);
  }
  componentWillUnmount() {
    if (this._intervalID) clearInterval(this._intervalID);
  }
  renderHomeBar() {
    return <div className="navigation home">Hilfe für Gemeinde Gottes</div>;
  }

  render() {
    const { content, selectContent } = this.props;

    return (
      <div
        className={`content-button`}
        onClick={() => (selectContent ? selectContent(content) : null)}
      >
        {content.thumbnail ? (
          <div
            className="preview"
            style={{ backgroundImage: `url(${content.thumbnail})` }}
          />
        ) : null}
        <div className="center">
          <div className="title">{content.title}</div>
          {content.description ? (
            <div className="description">{content.description}</div>
          ) : null}
        </div>
        {content.type === "livestream" && Helper.checkLive(content) ? (
          <div className="live-indicator">
            <div className="circle" /> Gottesdienst läuft gerade
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({
  selectedContent: state.data.selected,
});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(ContentButton);
