import * as React from "react";
import { Loader } from "./components/Loader/Loader";
import "./App.scss";
import { AppState } from "./redux/Store";
import { connect } from "react-redux";
import Navigation from "./components/Navigation/Navigation";
import {
  ContentEntry,
  ContentEntryYoutubePlaylist,
} from "./model/ContentEntry";
import HomeView from "./views/home/HomeView";
import YTPlaylistView from "./views/YTPlaylistView/YTPlaylistView";
import YTCanalView from "./views/YTCanalView/YTCanalView";
import InternetRadioView from "./views/IntenetRadioView/InternetRadioView";
import LiveStreamView from "./views/LiveStreamView/LiveStreamView";
import axios from "axios";
import { loadContent } from "@/redux/data/DataActions";
import { setFullscreen } from "@/redux/config/ConfigActions";

import { DataActionLoadContent } from "./redux/data/DataTypes";
import AudioRSSFeedView from "./views/AudioRSSFeedView/AudioRSSFeedView";
import * as screenfull from "screenfull";
import { ConfigActionFullscreen } from "./redux/config/ConfigTypes";

const url = "https://gglauncher.bennihoss.de";
type StateProps = {
  selectedContent: ContentEntry | null;
};
type DispatchProps = {
  loadContent: (content: ContentEntry[]) => DataActionLoadContent;
  setFullscreen: (fullscreen: boolean) => ConfigActionFullscreen;
};
type Props = {};

type State = {
  initalizing: boolean;
  error: null | string;
};

class ApplicationRoot extends React.Component<
  Props & StateProps & DispatchProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {
    initalizing: true,
    error: null,
  };

  componentDidMount() {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        this.props.setFullscreen((screenfull as any).isFullscreen);
      });
    }

    axios
      .get(`${url}/data.php`)
      .then((response) => {
        try {
          console.log(response.data);
          const data = response.data;
          if (typeof data === "object") {
            this.props.loadContent(response.data);
            window.localStorage.setItem("content", JSON.stringify(data));
          } else {
            console.error("no valid json");
            const content = window.localStorage.getItem("content");
            if (content) {
              this.props.loadContent(JSON.parse(content));
            }
          }
        } catch (err) {
          console.error(err);
          const content = window.localStorage.getItem("content");
          if (content) {
            this.props.loadContent(JSON.parse(content));
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ error: err.toString() });
        const content = window.localStorage.getItem("content");
        if (content) {
          this.props.loadContent(JSON.parse(content));
        }
      })
      .finally(() => {
        this.setState({ initalizing: false });
      });
  }

  renderInitialLoading() {
    return (
      <div className={`app-loading`}>
        <Loader text="Lade Daten, bitte einen Moment Geduld" />
      </div>
    );
  }

  renderContent() {
    const { selectedContent } = this.props;
    if (!selectedContent) {
      return (
        <>
          <HomeView />
          {this.state.error ? this.state.error : null}
        </>
      );
    } else {
      switch (selectedContent.type) {
        case "yt-playlist":
          return <YTPlaylistView content={selectedContent} />;
        case "yt-canal":
          return <YTCanalView content={selectedContent} />;

        case "internet-radio":
          return <InternetRadioView content={selectedContent} />;
        case "livestream":
          return <LiveStreamView content={selectedContent} />;
        case "rss-feed-audio":
          return <AudioRSSFeedView content={selectedContent} />;
        default:
          return null;
      }
    }
  }
  render() {
    const { initalizing } = this.state;
    if (initalizing) {
      return this.renderInitialLoading();
    }

    return this.renderContent();
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({
  selectedContent: state.data.selected,
});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { loadContent, setFullscreen },
  mergeProps
)(ApplicationRoot);
