import * as moment from "moment";
import { ContentEntryLiveStream } from "./../../model/ContentEntry";
class HelperClass {
  checkLive(contentEntry: ContentEntryLiveStream) {
    const now = moment();

    const found = contentEntry.live.find((liveDate) => {
      const from = moment();
      from.weekday(liveDate.dayOfWeek);
      from.hour(liveDate.fromHour);
      from.minute(liveDate.fromMinute);
      const to = moment();
      to.weekday(liveDate.dayOfWeek);
      to.hour(liveDate.untilHour);
      to.minute(liveDate.untilMinute);

      return now.isAfter(from) && now.isBefore(to);
    });

    return !!found;
  }
}
const Helper = new HelperClass();
export default Helper;
