import * as React from "react";
import "./InternetRadioView.scss";
import { AppState } from "../../redux/Store";
import { connect } from "react-redux";
import {
  ContentEntry,
  ContentEntryYoutubePlaylist,
  ContentEntryInternetRadio,
} from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import ContentButton from "@/components/ContentButton/ContentButton";
import Navigation from "@/components/Navigation/Navigation";
import NavigationButton from "@/components/Navigation/NavigationButton";
import "@public/img/playNext.png";
import "@public/img/playPrev.png";
type StateProps = {};
type DispatchProps = {};
type Props = {
  content: ContentEntryInternetRadio;
};

type State = {};

class InternetRadioView extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {};

  render() {
    const { content } = this.props;

    return (
      <div className={`application-layout`}>
        <Navigation backButton={true}>
          {content.thumbnail ? (
            <div
              className="preview"
              style={{ backgroundImage: `url(${content.thumbnail})` }}
            />
          ) : null}
          <div className={"title"}>{content.title}</div>

          <div className="fill" />
        </Navigation>
        <div className={`content`}>
          <div className={`internat-radio-view`}>
            <audio autoPlay id="audio_1" controls preload="none">
              <source src={content.url} type="audio/mpeg" />
            </audio>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(InternetRadioView);
