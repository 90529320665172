export interface ConfigState {
  fullscreen: boolean;
}

export const CONFIG_FULLSCREEN = "CONFIG_FULLSCREEN";

export interface ConfigActionFullscreen {
  type: typeof CONFIG_FULLSCREEN;
  fullscreen: boolean;
}

export type ConfigActionTypes = ConfigActionFullscreen;
