import { ContentEntry } from "./../../model/ContentEntry";

export interface DataState {
  content: ContentEntry[];
  selected: ContentEntry | null;
}

export const DATA_LOAD_CONTENT = "DATA_LOAD_CONTENT";
export const DATA_SELECT_CONTENT = "DATA_SELECT_CONTENT";

export interface DataActionLoadContent {
  type: typeof DATA_LOAD_CONTENT;
  content: ContentEntry[];
}
export interface DataActionSelectContent {
  type: typeof DATA_SELECT_CONTENT;
  content: ContentEntry | null;
}

export type DataActionTypes = DataActionLoadContent | DataActionSelectContent;
