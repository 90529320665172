import { dataReducer } from "./data/DataReducer";
import { configReducer } from "./config/ConfigReducer";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { DataState } from "./data/DataTypes";
import { ConfigState } from "./config/ConfigTypes";

export interface StoreInterface {
  data: DataState;
  config: ConfigState;
}

const rootReducer = (state: AppState, action: any) => {
  const newState = {} as AppState;

  newState["data"] = dataReducer(state ? state.data : undefined, action, state);
  newState["config"] = configReducer(
    state ? state.config : undefined,
    action,
    state
  );

  return newState;
};
export type AppState = StoreInterface;

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);
