import * as React from "react";
import "./LiveStreamView.scss";
import { AppState } from "../../redux/Store";
import { connect } from "react-redux";
import {
  ContentEntry,
  ContentEntryYoutubePlaylist,
  ContentEntryLiveStream,
} from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import ContentButton from "@/components/ContentButton/ContentButton";
import Navigation from "@/components/Navigation/Navigation";
import NavigationButton from "@/components/Navigation/NavigationButton";
import "@public/img/playNext.png";
import "@public/img/playPrev.png";
import * as moment from "moment";
import Helper from "@/components/utils/Helper";

type StateProps = {};
type DispatchProps = {};
type Props = {
  content: ContentEntryLiveStream;
};

type State = {};

class LiveStreamView extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {};

  componentDidMount() {
    (frames[0].window as any).initialize = function () {
      const frame = frames[0].window as Window;

      const obj = frame.document.querySelector("iframe");
      if (obj) {
        frame.document.body.append(obj);
        const url = obj.getAttribute("src");
        obj.setAttribute("src", url + "&autoplay=1");
        const css =
          "iframe { position: fixed; display:block; top: 0; left:0; bottom: 0; right:0; width: 100%;height:100%; z-index:99999} ";
        const head =
          frame.document.head || frame.document.getElementsByTagName("head")[0];
        const style = document.createElement("style");

        head.appendChild(style);

        style.type = "text/css";
        if ((style as any).styleSheet) {
          // This is required for IE8 and below.
          (style as any).styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      } else {
        console.log("no iframe found", window, frames[0].window);
      }
    };

    //removes the further videos frame which is shown when the video pauses
    setTimeout(() => {
      (frames[0].window as any).initialize();
    }, 1000);
  }

  render() {
    const { content } = this.props;
    return (
      <div className={`application-layout`}>
        <Navigation backButton={true}>
          {content.thumbnail ? (
            <div
              className="preview"
              style={{ backgroundImage: `url(${content.thumbnail})` }}
            />
          ) : null}
          <div className={"title"}>{content.title}</div>
          <div className="fill" />
          {Helper.checkLive(content) ? (
            <div className="live-indicator">
              <div className="circle" /> Gottesdienst läuft gerade
            </div>
          ) : null}
        </Navigation>
        <div className={`content`}>
          <div className={`yt-playlist-view`}>
            <iframe
              id="frame-playlist"
              width="100%"
              height="100%"
              src={content.websiteUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(LiveStreamView);
