/**
 * React renderer.
 */
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./../redux/Store";
import ApplicationRoot from "./../App";
ReactDOM.render(
  <Provider store={store}>
    <ApplicationRoot />
  </Provider>,
  document.getElementById("app")
);
