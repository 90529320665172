import * as React from "react";
import "./NavigationButton.scss";
import { AppState } from "./../../redux/Store";
import { connect } from "react-redux";
import { ContentEntry } from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";

type Props = {
  text: string;
  icon: string;
  onClick: () => void;
};

type State = {};

class NavigationButton extends React.Component<Props, State> {
  static defaultProps = {};

  readonly state: State = {};

  render() {
    const { text, icon, onClick } = this.props;

    return (
      <div className={`navigation-button`} onClick={() => onClick()}>
        <img src={icon} />
        <div className={`text`}>{text}</div>
      </div>
    );
  }
}

export default NavigationButton;
