import * as React from "react";
import "./Navigation.scss";
import { AppState } from "./../../redux/Store";
import { connect } from "react-redux";
import { ContentEntry } from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import NavigationButton from "./NavigationButton";
import "@public/img/back.png";

type StateProps = {
  selectedContent: ContentEntry | null;
};
type DispatchProps = {
  selectContent?: (content: ContentEntry | null) => DataActionSelectContent;
};
type Props = {
  backButton: boolean;
  children: React.ReactNode;
};

type State = {};

class Navigation extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {
    backButton: false,
  };

  readonly state: State = {};

  render() {
    const { selectedContent, backButton, selectContent } = this.props;

    return (
      <div className={`navigation `}>
        {backButton ? (
          <NavigationButton
            onClick={() => (selectContent ? selectContent(null) : null)}
            icon="public/img/back.png"
            text="Zurück"
          />
        ) : null}
        <div className="nav-content">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({
  selectedContent: state.data.selected,
});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(Navigation);
