import * as React from "react";
import "./YTCanalView.scss";
import { AppState } from "../../redux/Store";
import { connect } from "react-redux";
import {
  ContentEntry,
  ContentEntryYoutubePlaylist,
  ContentEntryYoutubeCanal,
} from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import ContentButton from "@/components/ContentButton/ContentButton";
import Navigation from "@/components/Navigation/Navigation";
import NavigationButton from "@/components/Navigation/NavigationButton";
import "@public/img/playNext.png";
import "@public/img/playPrev.png";
type StateProps = {};
type DispatchProps = {};
type Props = {
  content: ContentEntryYoutubeCanal;
};

type State = {};

class YTCanalView extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {};

  componentDidMount() {
    (frames[0].window as any).playNext = function () {
      const obj = (frames[0].window as any).document.querySelector(
        ".ytp-next-button"
      );
      if (obj) {
        obj.click();
      } else {
        console.log("not found", window, frames[0].window);
      }
      // ytp-pause-overlay ytp-scroll-min
    };

    (frames[0].window as any).playPrevious = function () {
      const obj = (frames[0].window as any).document.querySelector(
        ".ytp-prev-button"
      );
      if (obj) {
        obj.click();
      } else {
        console.log("not found", window, frames[0].window);
      }
      // ytp-pause-overlay ytp-scroll-min
    };
    //removes the further videos frame which is shown when the video pauses
    setTimeout(() => {
      (frames[0].window as any).clearYTFrame = function () {
        const obj = (frames[0].window as any).document.querySelector(
          ".ytp-pause-overlay"
        );
        if (obj) {
          console.log(obj);
          obj.remove();
        } else {
          console.log("not found", window, frames[0].window);
        }
        // ytp-pause-overlay ytp-scroll-min
      };
      (frames[0].window as any).clearYTFrame();
    }, 1000);

    (window as any).next = () => this.playNext();
  }

  playNext() {
    (frames[0].window as any).playNext();
  }

  playPrevious() {
    (frames[0].window as any).playPrevious();
  }

  render() {
    const { content } = this.props;

    return (
      <div className={`application-layout`}>
        <Navigation backButton={true}>
          {content.thumbnail ? (
            <div
              className="preview"
              style={{ backgroundImage: `url(${content.thumbnail})` }}
            />
          ) : null}
          <div className={"title"}>{content.title}</div>
          <NavigationButton
            text={"Zurück"}
            icon="public/img/playPrev.png"
            onClick={() => this.playPrevious()}
          />
          <NavigationButton
            text={"Weiter"}
            icon="public/img/playNext.png"
            onClick={() => this.playNext()}
          />
          <div className="fill" />
        </Navigation>
        <div className={`content`}>
          <div className={`yt-playlist-view`}>
            <iframe
              id="frame-playlist"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed?listtype=playlist&list=${content.channelId}&autoplay=1&enablejsapi=1&loop=1&rel=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(YTCanalView);
