import * as React from "react";
import "./AudioRSSFeedView.scss";
import { AppState } from "../../redux/Store";
import { connect } from "react-redux";
import { ContentEntry, ContentEntryRSSFeedAudio } from "@/model/ContentEntry";
import { selectContent } from "@/redux/data/DataActions";
import Navigation from "@/components/Navigation/Navigation";
import "@public/img/playNext.png";
import "@public/img/playPrev.png";
import axios from "axios";
import { Loader } from "@/components/Loader/Loader";

interface AudioObj {
  id: string;
  url: string;
  title: string;
  description: string;
  type: string;
}

type StateProps = {};
type DispatchProps = {
  selectContent: (content: ContentEntry | null) => void;
};
type Props = {
  content: ContentEntryRSSFeedAudio;
};

type State = {
  loading: boolean;
  feedObj: Document | null;
  audioObjects: AudioObj[];
  currentRunning: string | null;
};

class AudioRSSFeedView extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {
    loading: true,
    feedObj: null,
    audioObjects: [],
    currentRunning: null,
  };

  componentDidMount() {
    const { content } = this.props;

    axios
      .get(content.url, {
        responseType: "text",
      })
      .then((response) => {
        // console.log(response.data);
        const parser = new DOMParser();
        const feedObj = parser.parseFromString(response.data, "text/xml");
        const audioObjects: AudioObj[] = [];
        feedObj.querySelectorAll("item").forEach((item, index) => {
          console.log(item);
          const title = (item.querySelector("title") as any).innerHTML;
          const description = (item.querySelector("description") as any)
            .innerHTML;
          const id = "track_" + index;
          const url = (item.querySelector("enclosure") as any).getAttribute(
            "url"
          );
          const type = (item.querySelector("enclosure") as any).getAttribute(
            "type"
          );
          audioObjects.push({
            id,
            url,
            title,
            description,
            type,
          });
        });

        this.setState({
          feedObj,
          audioObjects,
        });
        console.log(feedObj);
      })
      .catch((err) => {
        console.log(err);
        this.props.selectContent(null);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  renderAudioObj(obj: AudioObj) {
    const { currentRunning } = this.state;
    return (
      <div
        className={`audio-object ${currentRunning === obj.id ? "active" : ""}`}
        onClick={() => {
          if (this.state.currentRunning === obj.id) {
            (document.querySelector(`#${obj.id}`) as any).pause();
          } else {
            (document.querySelector(`#${obj.id}`) as any).play();
          }
        }}
      >
        <div className="title">{obj.title}</div>
        <div className="description">{obj.description}</div>
        <audio
          id={obj.id}
          controls
          controlsList="nodownload"
          preload="none"
          onPlay={() => {
            if (this.state.currentRunning) {
              (document.querySelector(
                `#${this.state.currentRunning}`
              ) as any).pause();
            }
            this.setState({ currentRunning: obj.id });
          }}
          onPause={() => {
            if (this.state.currentRunning === obj.id)
              this.setState({ currentRunning: null });
          }}
          onAbort={() => {
            if (this.state.currentRunning === obj.id)
              this.setState({ currentRunning: null });
          }}
          onEnded={() => {
            if (this.state.currentRunning === obj.id)
              this.setState({ currentRunning: null });
          }}
        >
          <source src={obj.url} type={obj.type} />
        </audio>
      </div>
    );
  }

  render() {
    const { content } = this.props;
    const { feedObj, loading, audioObjects } = this.state;

    let obj = [];
    if (feedObj) {
      feedObj.querySelectorAll("item");
    }
    return (
      <div className={`application-layout`}>
        <Navigation backButton={true}>
          {content.thumbnail ? (
            <div
              className="preview"
              style={{ backgroundImage: `url(${content.thumbnail})` }}
            />
          ) : null}
          <div className={"title"}>{content.title}</div>

          <div className="fill" />
        </Navigation>
        <div className={`content`}>
          <div className={`audio-rss-view`}>
            {loading ? (
              <div className="loader-container">
                <Loader text="Lade Daten, bitte einen Moment Geduld" />
              </div>
            ) : (
              <div className="audio-list">
                {audioObjects.map((audioObj) => this.renderAudioObj(audioObj))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { selectContent },
  mergeProps
)(AudioRSSFeedView);
