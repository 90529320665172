import {
  DataActionLoadContent,
  DATA_LOAD_CONTENT,
  DATA_SELECT_CONTENT,
  DataActionSelectContent,
} from "./DataTypes";
import { ContentEntry } from "./../../model/ContentEntry";

export function loadContent(content: ContentEntry[]): DataActionLoadContent {
  console.log("content");
  return {
    type: DATA_LOAD_CONTENT,
    content,
  };
}

export function selectContent(
  content: ContentEntry | null
): DataActionSelectContent {
  return {
    type: DATA_SELECT_CONTENT,
    content,
  };
}
