import * as React from "react";
import "./Loader.scss";
type Props = {
  loading: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  text: string | null;
};

type State = {};

export class Loader extends React.Component<Props, State> {
  static defaultProps = {
    loading: true,
    size: "md",
    text: null,
  };

  readonly state: State = {};

  render() {
    const { loading, text, size } = this.props;
    if (!loading) {
      return null;
    }

    return (
      <div className={`loader-container size-${size}`}>
        <div className="loader">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        {text ? <div className={`text`}>{text}</div> : null}
      </div>
    );
  }
}
