import {
  DataState,
  DataActionTypes,
  DATA_LOAD_CONTENT,
  DATA_SELECT_CONTENT,
} from "./DataTypes";
import { AppState } from "../Store";

const initialState: DataState = {
  content: [],
  selected: null,
};

export function dataReducer(
  state = initialState,
  action: DataActionTypes,
  appState: AppState
): DataState {
  switch (action.type) {
    case DATA_LOAD_CONTENT:
      return {
        ...state,
        content: action.content,
      };
    case DATA_SELECT_CONTENT:
      return {
        ...state,
        selected: action.content,
      };
    default:
      return state;
  }
}
