import {
  ConfigState,
  ConfigActionTypes,
  CONFIG_FULLSCREEN,
} from "./ConfigTypes";
import { AppState } from "../Store";

const initialState: ConfigState = {
  fullscreen: false,
};

export function configReducer(
  state = initialState,
  action: ConfigActionTypes,
  appState: AppState
): ConfigState {
  switch (action.type) {
    case CONFIG_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen,
      };
    default:
      return state;
  }
}
