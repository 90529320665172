import { CONFIG_FULLSCREEN, ConfigActionFullscreen } from "./ConfigTypes";
import * as screenfull from "screenfull";

export function setFullscreen(fullscreen: boolean): ConfigActionFullscreen {
  if (fullscreen) {
    if (screenfull.isEnabled) {
      screenfull.request();
    } else {
    }
  } else {
    if (screenfull.isEnabled) {
      screenfull.exit();
    } else {
    }
  }
  return {
    type: CONFIG_FULLSCREEN,
    fullscreen,
  };
}
