import * as React from "react";
import "./HomeView.scss";
import { AppState } from "./../../redux/Store";
import { connect } from "react-redux";
import { ContentEntry } from "@/model/ContentEntry";
import { DataActionSelectContent } from "@/redux/data/DataTypes";
import { selectContent } from "@/redux/data/DataActions";
import ContentButton from "@/components/ContentButton/ContentButton";
import Navigation from "@/components/Navigation/Navigation";
import { setFullscreen } from "@/redux/config/ConfigActions";
import { ConfigActionFullscreen } from "@/redux/config/ConfigTypes";
import NavigationButton from "@/components/Navigation/NavigationButton";
import "@public/img/fullscreen.png";
import "@public/img/leave-fullscreen.png";

type StateProps = {
  content: ContentEntry[] | null;
  isFullscreen: boolean;
};
type DispatchProps = {
  setFullscreen: (fullscreen: boolean) => ConfigActionFullscreen;
};
type Props = {};

type State = {};

class HomeView extends React.Component<
  Props & DispatchProps & StateProps,
  State
> {
  static defaultProps = {};

  readonly state: State = {};
  render() {
    const { content } = this.props;

    return (
      <div className={`application-layout`}>
        <Navigation backButton={false}>
          <div className="fill" />
          <div className="title">Inhalte der Gemeinde Gottes</div>
          <div className="fill" />
          {this.props.isFullscreen ? (
            <NavigationButton
              text={"Vollbild verlassen"}
              icon="public/img/leave-fullscreen.png"
              onClick={() => this.props.setFullscreen(false)}
            />
          ) : (
            <NavigationButton
              text={"Vollbild"}
              icon="public/img/fullscreen.png"
              onClick={() => this.props.setFullscreen(true)}
            />
          )}
        </Navigation>
        <div className={`content`}>
          <div className={`home-view`}>
            {content
              ? content.map((contentEntry) =>
                  !contentEntry.disabled ? (
                    <ContentButton
                      key={contentEntry.id}
                      content={contentEntry}
                    />
                  ) : null
                )
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props): StateProps => ({
  content: state.data.content,
  isFullscreen: state.config.fullscreen,
});
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(
  mapStateToProps,
  { setFullscreen },
  mergeProps
)(HomeView);
